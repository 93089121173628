<template src="./Idi.html"></template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import idiService from "../../services/Idi";
import ArrobaMedellinHorizontalCardIdi from "@/components/ArrobaMedellinHorizontalCardIdi/ArrobaMedellinHorizontalCardIdi";
import ArrobaMedellinVerticalCardIdi from "@/components/ArrobaMedellinVerticalCardIdi/ArrobaMedellinVerticalCardIdi";
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ArrobaMedellinNoData from "@/components/ArrobaMedellinNoData/ArrobaMedellinNoData";
import NewGroupModal from "./NewGroupModal/NewGroupModal";
import EditGroupModal from "./EditGroupModal/EditGroupModal";
import swiperSmallOption from "@/utils/swiper-small-option";
import swiperLargeOption from "@/utils/swiper-large-option";
import "swiper/css/swiper.css";
import { roleName } from "@/utils/constants";
import tutorial from "@/mixins/tutorial";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import AnnouncementOpen from "../../components/AnnouncementOpen/AnnouncementOpen";
import NewsVerticalCard from "../../components/NewsVerticalCard/NewsVerticalCard";
import HorizontalCardComingSoon from "../../components/HorizontalCardComingSoon/HorizontalCardComingSoon";
import VerticalCardComingSoon from "../../components/VerticalCardComingSoon/VerticalCardComingSoon";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import AnnouncementCloseHorizontal from "../../components/AnnouncementCloseHorizontal/AnnouncementCloseHorizontal";
import announcementService from "../../services/Announcement";
import newsService from "../../services/News";

export default {
  name: "idi",
  mixins: [tutorial],
  components: {
    Swiper,
    SwiperSlide,
    ArrobaMedellinHorizontalCardIdi,
    ArrobaMedellinVerticalCardIdi,
    ArrobaMedellinSeeker,
    NewGroupModal,
    ArrobaMedellinArrobitaNoData,
    EditGroupModal,
    ArrobaMedellinNoData,
    ArrobaMedellinHorizontalCard,
    AnnouncementOpen,
    NewsVerticalCard,
    AnnouncementCloseHorizontal,
    HorizontalCardComingSoon,
    VerticalCardComingSoon,
    ChooseUniversity,
  },
  data() {
    return {
      errorMessage: false,
      typeCard1: { button: "button--red", size: "small" },
      typeCard: { button: "button--red" },
      swiperSmallOption: swiperSmallOption,
      swiperLargeOption: swiperLargeOption,
      itemsToShow: 6,
      itemsAccordingToYourProfileItem: 2,
      itemsMyGroup: 4,
      itemsAllOpenCalls: 2,
      itemsAllNews: 2,
      role: "",
      allGroups: [],
      myGroups: [],
      otherResearchGorups: [],
      openNewGroupModal: false,
      openEditModal: false,
      groupToEdit: null,
      roleName,
      openCalls: [],
      closedCalls: [],
      allNews: [],
      myCalls: [],
      modalApply: false,
      institutionId: "",
      infoSearch: {},
    };
  },
  created() {
    this.institutionId = this.user.institutionId.id;
    this.role = this.user.role.name;
    if (
      this.role !== "AdministratorCall" ||
      this.role !== "TeacherInvestigator" ||
      this.role !== "University" ||
      this.role !== "Administrator"
    ) {
      this.getIdiInformation();
    }
  },
  mounted() {
    window.addEventListener("resize", this.reportWindowSize);
    this.reportWindowSize();
    this.redirectToAdminCalls();
    this.redirectToIdiResearchProfessor();
    this.redirectToIdiUniversity();
    this.redirectToIdiAdministator();
    this.dataTutorial("idi");
  },
  methods: {
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsAccordingToYourProfileItem = Number(1);
        this.itemsToShow = Number(1);
        this.itemsMyGroup = Number(1);
      } else if (window.innerWidth < 1024) {
        this.itemsToShow = Number(3);
        this.itemsAccordingToYourProfileItem = Number(2);
        this.itemsMyGroup = Number(2);
      } else if (window.innerWidth < 1280 || window.innerWidth > 1280) {
        this.itemsToShow = Number(5);
        this.itemsAccordingToYourProfileItem = Number(3);
        this.itemsMyGroup = Number(3);
      }
    },
    async getIdiSearch(word) {
      this.errorMessage = false;
      this.openCalls = [];
      this.closedCalls = [];
      try {
        if (!word) {
          this.getIdiInformation();
        } else {
          this.infoSearch = await announcementService.getSearchAnnouncement({
            word: word,
            take: 6,
            skip: 0,
            publicState: "both",
            institutionId: this.institutionId,
          });
          this.openCalls = this.infoSearch.open;
          this.closedCalls = this.infoSearch.close;
          if (!this.openCalls.length && !this.closedCalls.length)
            this.errorMessage = true;
        }
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Ocurrio un error.",
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async getIdiInformation() {
      try {
        this.allGroups = [];
        this.myGroups = [];
        this.openCalls = [];
        this.closedCalls = [];
        this.allNews = [];
        this.myCalls = [];
        if (this.role === roleName.ADMINISTRATOR) {
          const { items } = await idiService.listGroups();
          this.allGroups = items;
        } else {
          const objOpenCalls = {
            state: true,
            institution: this.institutionId,
            publicState: "both",
          };
          const objClosedCalls = {
            state: false,
            institution: this.institutionId,
            publicState: "both",
          };
          const objNews = {
            id: this.institutionId,
            newsType: "institution",
            institutionId: this.institutionId,
          };
          this.myGroups = await idiService.myGroups();
          this.otherResearchGorups = await idiService.listOtherGroups();
          this.openCalls =
            await announcementService.announcementByStateAndInstitution(
              objOpenCalls
            );
          this.closedCalls =
            await announcementService.announcementByStateAndInstitution(
              objClosedCalls
            );
          this.allNews = await newsService.newsByElement(objNews);
          this.myCalls = await announcementService.myAnnouncementByState(1);
        }
      } catch (error) {
        console.error(error);
      }
    },
    openTheNewGroupModal() {
      this.openNewGroupModal = true;
    },
    openTheEditGroupModal(group) {
      const {
        name,
        description,
        registrationNumber,
        institutionName,
        institutionId,
        id,
        category,
        coordinator,
      } = group;
      this.groupToEdit = {
        name,
        description,
        registrationNumber,
        institutionName,
        institutionId,
        id,
        category,
        coordinator,
      };
      this.openEditModal = true;
    },
    closeTheNewGroupModal() {
      this.openNewGroupModal = false;
    },
    closeTheEditGroupModal() {
      this.groupToEdit = null;
      this.openEditModal = false;
    },
    async closeTheEditGroupModalAndRefresh() {
      this.groupToEdit = null;
      this.openEditModal = false;
      this.getIdiInformation();
    },
    async closeNewGroupModalAndRefresh() {
      this.openNewGroupModal = false;
      this.getIdiInformation();
    },
    redirectTo(path, id) {
      this.$router.push({ name: path, params: { id: id } });
    },
    activePopup() {
      this.activepopup = !this.activepopup;
    },
    redirectToAdminCalls() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "AdministratorCall") {
        this.$router.push("/administradorConvocatorias");
      }
    },
    redirectToIdiResearchProfessor() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "TeacherInvestigator") {
        this.$router.push("/idiProfesorInvestigador");
      }
    },
    redirectToIdiUniversity() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "University") {
        this.$router.push("/idiUniversidad");
      }
    },
    redirectToIdiAdministator() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "Administrator") {
        this.$router.push("/idiAdministrador");
      }
    },
    modalResearchProfessor() {
      this.modalApply = true;
    },
    async applyResearchProfessor() {
      try {
        const result = await idiService.applyResearchProfessor();
        const message = result?.message ?? "Solicitud enviada";
        this.modalApply = false;
        this.$buefy.toast.open({
          duration: 5000,
          message,
          position: "is-bottom",
          type: "is-success",
        });
      } catch (error) {
        console.error("Error apply research professor", error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-primary",
        });
        this.modalApply = false;
      }
    },
  },
  computed: {
    roleValidation() {
      return ["Student", "Teacher", "Entrepreneur"].some(
        (a) => a === this.role
      );
    },
    roleValidationEntrepreneur() {
      return ["Entrepreneur"].some((a) => a === this.role);
    },
    roleValidationAdminAnnouncement() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
    roleValidationTeacher() {
      return ["Teacher"].some((a) => a === this.role);
    },
    roleValidationInvited() {
      return ["Invited"].some((a) => a === this.role);
    },
    calculateCommingSoon() {
      return (quantity, max) => {
        const dif = max - quantity;
        return dif < 0 ? [] : Array(dif);
      };
    },
    ...mapGetters({
      user: "getUser",
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getIdiInformation();
    },
  },
};
</script>

<style lang="scss" scoped src="./Idi.scss"></style>
